import { withLayout } from "../../hooks/withLayout"

const GroupsPage = () => {
    return (
        <div>
            Groups page
        </div>
    )
}


const Groups = withLayout(GroupsPage)
export default Groups
