import { withLayout } from "../../hooks/withLayout"

const VideosPage = () => {
    return (
        <div>
            Watch page
        </div>
    )
}

const Videos = withLayout(VideosPage)
export default Videos
