export const CITIES = [
  'Cities',
  'Vinnytsia',
  'Dnipro',
  'Donetsk',
  'Zhytomyr',
  'Uzhhorod',
  'Zaporizhzhia',
  'Ivano-Frankivsk',
  'Kyiv',
  'Kropyvnytskyi',
  'Luhansk',
  'Lutsk',
  'Lviv',
  'Mykolaiv',
  'Odessa',
  'Poltava',
  'Rivne',
  'Sevastopol',
  'Simferopol',
  'Sumy',
  'Ternopil',
  'Kharkiv',
  'Kherson',
  'Khmelnytskyi',
  'Cherkasy',
  'Chernivtsi',
  'Chernihiv',
]
